@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Rubik";
  box-sizing: border-box;
  // font-family: 'Rubik', sans-serif;
}

.btn-primary {
  background: linear-gradient(99deg, #2ecf96 0%, #1c9d87 100%);
  border-radius: 5px;
  color: white;
  border: none;
}

.btn-secondary {
  background: #f8a492;
  border: 1px solid #f8a492;
  border-radius: 5px;
  color: white;
}

.btn-primary,
.btn-outline,
.btn-secondary,
.btn-load-more {
  padding: 0.8rem 1.5rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  font-weight: 500;

  transition-duration: 0.3s;
  transition-property: transform;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;

  &:not(:disabled):hover {
    transform: translateY(-2px);
  }
}

.user-slider {
  .slick-list {
    margin: 0px -25px;
    padding-top: 60px;
    @media (max-width: 767.99px) {
      margin: 0;
    }
    .slick-item {
      position: relative;
      .sliderInner {
        margin: 0 25px;
        padding: 60px 50px 30px;
        background: #f0f4f3;
        border-radius: 20px;

        @media (max-width: 767.99px) {
          margin: 0 auto;
          width: 90%;
          padding: 60px 30px 30px;
        }
      }
    }
  }
  .slick-prev,
  .slick-next {
    width: 50px;
    height: 50px;
    top: -90px !important;
    @media (max-width: 767px) {
      top: 0 !important;
    }

    color: #a5b5af !important;
    &:hover {
      color: #2ecf96 !important;
    }
  }
  .slick-prev {
    right: 65px !important;
    left: unset !important;
  }
  .slick-next {
    right: 0;
  }
}
// ==============

.foodiary-menu {
  height: 10rem;
  .foodiary-menu-trigger {
    transition: all ease-in-out 0.3s;
    z-index: 9999;
    position: relative;
    // font-weight: 400;
    // font-size: 1rem;
    cursor: pointer;
    // margin: 1rem 1.3rem 0;
    color: #44554c;
    &:hover::after,
    &.active-nav-link::after {
      content: "";
      height: 0.3rem;
      width: 3rem;
      margin-inline: auto;
      background-color: #a5b5af;
      border-radius: 0.13rem;
      position: absolute;
      bottom: 1.9rem;
      left: calc(50% - 1.5rem);
      transition: all ease-in-out 0.3s;
    }

    &.active-nav-link::after {
      background-color: #2dcc95;
    }

    &.active,
    &.active-nav-link {
      color: #142c1f;
      &:hover::after {
        background-color: #2dcc95;
      }
    }
  }

  &:hover {
    .foodiary-menu-trigger {
      color: #000000;
    }
  }
  &:not(:hover) {
    .foodiary-menu-box {
      display: none;
    }
  }
}

.foodiary-menu-box {
  transition: all ease-in-out 0.3s;
  animation: fadeEffect 1s;
  position: fixed;
  top: 10rem;
  width: 100%;
  max-width: 29rem;
  z-index: 1999;
  .foodiary-menu-box-child {
    z-index: 1999;
    background-color: white;
    padding: 3rem;
    margin-top: 4rem;
    margin-inline: auto;
    border-radius: 0 0 0.9rem 0.9rem;
    box-shadow: #142c1f14 0rem 0.5rem 1rem;
    box-sizing: border-box;
  }

  .menu-item-link {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    display: block;
    color: #44554c;
    // font-weight: 400;
    // font-size: 1rem;
    position: relative;
    text-decoration: none;
    // font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI",
    //   Roboto, "Helvetica Neue", Arial, sans-serif;
    font-family: "Rubik";
    &:hover::before,
    &.active-nav-link::before {
      content: "";
      height: 90%;
      width: 0.2rem;
      background-color: #a5b5af;
      border-radius: 0.13rem;
      position: absolute;
      top: 5%;
      left: -1.33rem;
    }

    &.active-nav-link::before {
      background-color: #2dcc95;
    }

    &::after {
      display: none;
    }
  }
}

.menu-item-button-wrap {
  padding-top: 1.2rem;
  border-top: 1px solid #dddddd;
}

.btn-menu-outline {
  border: 2px solid #25a08b;
  padding: 10px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #25a08b;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  transition: all 0.2s ease-in-out;
  background-color: #ffffff;

  &:hover {
    background-color: #25a08b;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }
}

.hook-block {
  position: absolute;
  bottom: -160px;
  left: 50%;
  translate: -50% 0;
  @media (max-width: 600px) {
    bottom: -140px;
  }
}

.d-flex {
  display: flex !important;
}

.align-items-center {
  align-items: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.flex-row {
  flex-direction: row !important;
}

.function-icon {
  width: 6.43rem;
  height: 5.8288rem;
}

.function-info-text {
  position: absolute;
  left: 50%;
  top: 30%;
  translate: -50% -30%;
}

.dropdown-divider {
  border-top: 1px solid #cacaca7d;
  margin-bottom: 8px;
}

.grid-column-span-2 {
  grid-column: span 2;
}

.start-prevention-course-form {
  input[type="checkbox"] {
    width: 2rem;
    margin-right: 1.5rem;
    max-width: 2rem;
    min-width: 2rem;
  }

  .input-field {
    padding: 2rem;
    background-color: white;
    border-radius: 0.3rem;
    border: none;
    display: block;
    width: 100%;
  }

  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
}

#div_block-32-75 {
  justify-items: center;
  align-items: center;
  grid-template-columns: repeat(5, minmax(10px, 1fr));
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  display: grid;
  width: 100%;
  padding-top: 10px;
}

.disabled-dark {
  &:disabled {
    background: gray !important;
    color: white !important;
  }
}

#div_block-26-24 {
  align-items: stretch;
  grid-template-columns: repeat(2, minmax(200px, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  width: 100%;
  display: grid;
}

#text_block-19-24 {
  font-weight: 400;
  color: #c74343;
}

.dropdown-btn-modal {
  cursor: pointer;
  padding-right: 15px;

  &.open::after {
    content: "▲";
  }

  &::after {
    content: "▼";
    position: relative;
    right: -10px;
    font-size: 0.8em;
  }
}

.kontraindikationen {
  background-color: #f9f9f9;
  padding: 1rem;
  margin-top: 2rem;
  border-radius: 5px;
  width: 100%;
  text-align: left;
  gap: 1rem;
}
